<template>
  <div class="contacts">
    <div class="inner">
      <img src="../assets/img/kolomalogo.jpg" alt="">
      <div>
        <h2 style="order: 3;">{{ text.email[LANG] }}</h2>
        <a style="order: 4;" :href="'mailto:' + CONTACTS.email">{{ CONTACTS.email }}</a>
        <h2 style="order: 5;">{{ text.phone[LANG] }}</h2>
        <a style="order: 6;" :href="getPhoneNumber()">{{ CONTACTS.phone }}</a>
        <h2 class="soc-title">{{ text.socials[LANG] }}</h2>
        <div class="socials">
          <a target="_blank" :href="CONTACTS.facebook"><img src="../assets/img/facebook.svg" alt=""></a>
          <a target="_blank" :href="CONTACTS.instagram"><img src="../assets/img/instagram.svg" alt=""></a>
          <a target="_blank" :href="CONTACTS.tiktok"><img src="../assets/img/tiktok.svg" alt=""></a>
          <a target="_blank" :href="CONTACTS.telegram"><img src="../assets/img/telegram.svg" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "Contacts",
  data() {
    return {
      text: {
        email: {ua: 'Наша електронна пошта', en: 'Our email'},
        phone: {ua: 'Наш номер телефона', en: 'Our phone number'},
        socials: {ua: 'Соцiальнi мережi', en: 'Socials'},
      }
    }
  },
  computed: {
    ...mapGetters([
        'CONTACTS',
        'LANG'
    ])
  },
  methods: {
    getPhoneNumber() {
      if (!this.CONTACTS.phone) return ''
      else return 'tel:+' + this.CONTACTS.phone.replace(/\D/g,'')
    }
  }
}
</script>

<style scoped lang="scss" src="../assets/scss/contacts.scss"></style>
